import styles from './Gradient.css'

export function Gradient({ layoutClassName = undefined }) {
  const canvasRef = React.useRef(null)
  const size = 900

  React.useEffect(() => {
    const canvas = canvasRef?.current
    const ctx = canvas?.getContext('2d')

    const gradientOne = createRadialGradient(-500, 700, 0, size, '#63724a', 'rgba(99,82,74,0)', 700)
    const gradientTwo = createRadialGradient(300, 700, 500, 800, '#b7907e', 'rgba(183,144,126,0)', 500)
    const gradientThree = createRadialGradient(600, 700, 600, size, '#a57b6a', 'rgba(165,123,106,0)', 700)
    const gradientFour = createRadialGradient(size, 600, 600, size, '#914f36', 'rgba(162,98,71,0)', 800)

    drawToCtx(gradientOne)()
    drawToCtx(gradientThree)()
    drawToCtx(gradientTwo)()
    drawToCtx(gradientFour)()

    /** @param {number} xStart
      * @param {number} yStart
      * @param {number} xEnd
      * @param {number} yEnd
      * @param {string} color1
      * @param {string} color2
      * @param {number} endRadius
    **/

    function createRadialGradient(xStart, yStart, xEnd, yEnd, color1, color2, endRadius) {
      const radgrad = ctx.createRadialGradient(xStart, yStart, 1, xEnd, yEnd, endRadius || size)
      radgrad.addColorStop(0, color1)
      radgrad.addColorStop(1, color2)
      return radgrad
    }

    /** @param {CanvasGradient} gradient */

    function drawToCtx(gradient) {
      return () => {
        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, size, size)
      }
    }
  }, [])

  return (
    <div role='presentation' className={cx(styles.component, layoutClassName)}>
      <canvas className={styles.canvas} ref={canvasRef} width={size} height={size} />
    </div>
  )
}
